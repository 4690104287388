import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { PageTransition } from '../helpers/pageTransitions';
import { Intro, Lead, Section, Prefooter, CardList, Card } from '@partials';
import ModuleArea from '../helpers/moduleArea';

const Page = ({ data, location, pageContext }) => {
  const {
    title,
    leadText,
    seoMetaTags,
    content,
    pathways = [],
    showPathways
  } = data.page;

  const allPathways = [...pathways, ...data.globalConfig.defaultPathways].slice(
    0,
    3
  );

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />
      <PageTransition>
        <Section>
          <Intro title={title} breadcrumbs={pageContext.breadcrumbs}></Intro>
        </Section>
        {leadText && (
          <Section padding={'both'} animate={true}>
            <Lead body={leadText} />
          </Section>
        )}

        {content && (
          <Section animate={true}>
            <ModuleArea modules={content} location={location} />
          </Section>
        )}

        {showPathways && (
          <Section animate={true} background={'light-gray'} padding={'both'}>
            <CardList fullWidth={true}>
              {allPathways.map((pathway, i) => (
                <Card key={`pathway-${i}`} {...pathway} />
              ))}
            </CardList>
          </Section>
        )}

        <Section animate={true} background={'light-gray'} padding={'top'}>
          <Prefooter contactPagePath={pageContext.contactPagePath} />
        </Section>
      </PageTransition>
    </>
  );
};
export default Page;

export const query = graphql`
  query pageById($id: String!, $language: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      ...ContentModuleArea
      title
      id
      leadText
      treeParent {
        id
        slug
        title
      }
      pathways {
        ...Card
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      showPathways
    }
    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      defaultPathways {
        ...Card
      }
    }
  }
`;
